import React, { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import Popup from '@atlaskit/popup';
import ShowMoreIcon from '@atlaskit/icon/utility/show-more-horizontal';
import { LinkItem, MenuGroup } from '@atlaskit/menu';

import { fg } from '@confluence/feature-gating';
import {
	DEFAULT_ADMIN_MANAGE_APPS,
	MARKETPLACE_APP_REQUESTS,
	ADMIN_APP_AUDIT_LOGS,
} from '@confluence/named-routes';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';

const useLazyClickMoreActionsAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireAppsMoreActionsButtonClicked,
);

const useLazyClickMoreActionsItemAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireAppsMoreActionsDropdownItemClicked,
);

export const AppsMoreActionsMenu = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireClickMoreActionsAnalytics = useLazyClickMoreActionsAnalytics(createAnalyticsEvent);

	const onClick = () => {
		setIsOpen((v) => !v);
		void fireClickMoreActionsAnalytics();
	};

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			content={() => <AppsMoreActionsMenuContent />}
			trigger={(triggerProps) => (
				<IconButton
					icon={ShowMoreIcon}
					label={<FormattedMessage {...i18n.moreActions} />}
					onClick={onClick}
					spacing="compact"
					appearance="subtle"
					{...triggerProps}
				/>
			)}
			placement="bottom-start"
			shouldRenderToParent
		/>
	);
};

const AppsMoreActionsMenuContent = () => {
	const isAppRequestsEnabled = useBooleanFeatureFlag('confluence.frontend.enduser-emcee-discover');
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireClickMoreActionsItemManageAppsAnalytics = useLazyClickMoreActionsItemAnalytics(
		createAnalyticsEvent,
		'manageApps',
	);
	const fireClickMoreActionsItemAppAuditLogsAnalytics = useLazyClickMoreActionsItemAnalytics(
		createAnalyticsEvent,
		'appAuditLogs',
	);
	const fireClickMoreActionsItemViewAppRequestsAnalytics = useLazyClickMoreActionsItemAnalytics(
		createAnalyticsEvent,
		'viewAppRequests',
	);

	return (
		<MenuGroup>
			<LinkItem
				href={DEFAULT_ADMIN_MANAGE_APPS.toUrl()}
				target="_blank"
				onClick={fireClickMoreActionsItemManageAppsAnalytics}
			>
				<FormattedMessage {...i18n.manageApps} />
			</LinkItem>
			{fg('upm_frontend_deprecation') && (
				<LinkItem
					href={ADMIN_APP_AUDIT_LOGS.toUrl()}
					target="_blank"
					onClick={fireClickMoreActionsItemAppAuditLogsAnalytics}
				>
					<FormattedMessage {...i18n.appAuditLogs} />
				</LinkItem>
			)}
			{isAppRequestsEnabled && (
				<LinkItem
					href={MARKETPLACE_APP_REQUESTS.toUrl()}
					target="_blank"
					onClick={fireClickMoreActionsItemViewAppRequestsAnalytics}
				>
					<FormattedMessage {...i18n.viewAppRequests} />
				</LinkItem>
			)}
		</MenuGroup>
	);
};

const i18n = defineMessages({
	moreActions: {
		id: 'side-navigation.global.apps.more-actions',
		defaultMessage: 'More actions',
		description: 'Accessible label text for the ... button in the global navigation apps menu',
	},
	manageApps: {
		id: 'side-navigation.global.apps.manage-apps',
		defaultMessage: 'Manage apps',
		description: 'Manage apps link in the global navigation apps menu',
	},
	appAuditLogs: {
		id: 'side-navigation.global.apps.app-audit-log',
		defaultMessage: 'App audit logs',
		description: 'App audit logs link in the global navigation apps menu',
	},
	viewAppRequests: {
		id: 'side-navigation.global.apps.view-app-requests',
		defaultMessage: 'View app requests',
		description: 'View app requests link in the global navigation apps menu',
	},
});
